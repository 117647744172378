<template>
  <div class="h5-home">
    <div class="home-banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <template v-for="item of bannerList">
          <van-swipe-item>
            <img @click="onClickPageLink(item.link_url)" :src="item.resources_img">
          </van-swipe-item>
        </template>
      </van-swipe>
    </div>
    <div class="home_list">
      <div class="_item" v-for="item  of homeList">
        <img class="_pic2" :src="item.index_img" :alt="item.card_name" @click="onClickItem(item)"/>
      </div>
      <div style="font-size: 0.3rem; text-align: center; line-height: 3em;">
      </div>
    </div>
    <div class="show_modal" v-if="show_modal">
      <div class="show_content">
        <img class="show_img" src="http://pic.eaworks.cn/scbank/uploads/2024423/3129201.png" alt="">
        <div @click="show_modal=false" class="modal_btn_box">
          <div>确定</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Cookies from 'js-cookie';
import wx from 'weixin-js-sdk'
import page_links from "../mixin/page_links";

export default {
  mixins: [page_links],
  components: {},
  data() {
    return {
      pageHeight: 1200,
      homeList: [],
      bannerList: [],
      show_modal: false
    };
  },

  created() {
    if (this.$route.query.tester && this.$route.query.token) {
      localStorage.setItem('_wx_code', this.$route.query.token);
      Cookies.set('scbank_h5', this.$route.query.token);
    } else if (this.$route.query.logout) {
      Cookies.remove('scbank_h5');
      localStorage.clear();
    }
    this.checkWeixinCode(() => {
      this.ajaxGetCardData();
    });
    this.getImagesByLabel()
    //   this.ajaxGetCardData();
  },
  mounted() {
    let currTime = (new Date()).valueOf();
    if (currTime < 1714147200000) {
      this.show_modal = true;
    }
    this.getJsInfo();
    console.log("-> [home.vue] mounted");
    this.pageHeight = window.innerHeight || 1200;
  },

  methods: {
    async getJsInfo() {
      if (!window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) {
        return;
      }
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'https://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'https://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });
      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
    onClickItem(item) {
      if (item.status === 1) {
        return false;
      }
      let url = "/card?type=" + item.id
      this.$router.push(url);
    },

    async ajaxGetCardData() {
      let wxCode = localStorage.getItem("_wx_code");
      console.log("::-> [wxCode]", wxCode);
      // 获取首页卡片列表
      let {error, data} = await this.$ajax.gateway("/api/api_get_card_list", {
        ERROR_BACK: true,
        "METHOD": "get"
      });
      //避免指针拷贝
      this.homeList = data;
    },
    async getImagesByLabel() {
      console.log("-> [getImagesByLabel]");
      // 根据标签获取资源列表
      let {data} = await this.$ajax.gateway("/api/api_get_resource_by_label", {
        "body": {
          "label": "目录页banner"
        }
      });
      this.bannerList = data;
    },
  }
};
</script>

<style lang="less" scoped>
.h5-home {
  max-width: 750px;
  margin: 0 auto;
  position: relative;
  background: #f5f5f5;
}


.home_list {
  margin-top: 0.2rem;

  ._item {
    width: 100%;

    ._pic2 {
      width: 100%;
    }
  }
}

.home-banner {
  width: 100%;
  text-align: center;

  img {
    display: block;
    width: 100%;
  }
}

.show_modal {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;

  .show_content {
    padding-top: 2rem;
    text-align: center;

    .show_img {
      width: 80%;
      height: 50%;
      border-radius: 0.1rem;
      border-image: linear-gradient(to right, #e7d343, #f8a12b) 2;
      clip-path: inset(0 round 0.1rem);
    }
  }

  .modal_btn_box {
    margin-top: -1.5rem;
    text-align: center;
    font-size: 0.5rem;
    position: relative;

    div {
      background: repeating-linear-gradient(to right, #e7d343, #f8a12b);
      padding: 0.2rem 0.2rem 0.2rem 0.2rem;
      border-radius: 0.2rem;
      text-align: center;
      width: 50%;
      position: absolute;
      left: 22%;
      color: #ffffff;
    }
  }
}

</style>
